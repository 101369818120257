import type { RefObject } from 'preact';
import { useEffect } from 'preact/hooks';

export const useLeftPadding = (wrapperRef: RefObject<HTMLDivElement>, itemsRef: RefObject<HTMLDivElement>) => {
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (wrapperRef.current && itemsRef.current) {
                const { left } = wrapperRef.current.getBoundingClientRect();

                // NOTE: hardcore for var(--media-xs)
                if (window.matchMedia('screen and (max-width: 479px)').matches) {
                    itemsRef.current.style.paddingLeft = '16px';

                    return;
                }

                if (left >= 16) {
                    itemsRef.current.style.paddingLeft = `${left}px`;
                }
            }
        });

        resizeObserver.observe(document.body);

        return () => {
            resizeObserver.disconnect();
        };
    }, [wrapperRef, itemsRef]);
};
