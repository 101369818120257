import type { ComponentChildren, FunctionalComponent } from 'preact';
import { useRef } from 'preact/hooks';

import { Gallery } from 'src/shared/components/gallery/gallery.js';

import { BlockCardsProSlider } from '../block-cards-pro-slider/block-cards-pro-slider.js';
import { cnBlockCardsPro } from './block-cards-pro.constants.js';
import { useEqualHeights } from './block-cards-pro.hooks/use-equal-heights.js';

import './block-cards-pro.css';

type BlockAttributes = {
    children: ComponentChildren;
    type: 'slider' | 'gallery' | undefined;
};

export interface BlockCardsProProps extends BlockAttributes {}

export const BlockCardsPro: FunctionalComponent<BlockCardsProProps> = ({ children, type }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEqualHeights(ref);

    switch (type) {
        case 'slider':
            return (
                <div ref={ref} className={cnBlockCardsPro({ type })}>
                    <BlockCardsProSlider>{children}</BlockCardsProSlider>
                </div>
            );
        case 'gallery':
            return (
                <div ref={ref} className={cnBlockCardsPro({ type })}>
                    <Gallery>{children}</Gallery>
                </div>
            );
        default:
            return null;
    }
};
