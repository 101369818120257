import type { ClassNameFormatter } from '@bem-react/classname';
import type { RefObject } from 'preact';
import { useEffect } from 'preact/hooks';

import { cnBlockCardsProItem } from 'src/features/blocks/block-cards-pro-item/block-cards-pro-item.constants.js';

const makeSelectorFromCn = (fn: ClassNameFormatter, element?: string) => {
    const classes = element ? fn(element) : fn();

    return '.' + classes.split(' ').join('.');
};

const WATCH_ELEMENTS = ['name', 'profession', 'rating', 'description', 'years', 'companies', 'social-link'];

export const useEqualHeights = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
        const adjustHeights = () => {
            const container = ref.current;

            if (!container) {
                return;
            }

            for (const elementSelector of WATCH_ELEMENTS) {
                const elements = container.querySelectorAll(makeSelectorFromCn(cnBlockCardsProItem, elementSelector));

                for (const element of elements) {
                    (element as HTMLElement).style.height = '';
                }

                let maxHeight = -1;
                let hasContent = false;

                for (const element of elements) {
                    const { height } = (element as HTMLElement).getBoundingClientRect();

                    if (height > maxHeight) {
                        maxHeight = height;
                    }

                    if (element.textContent) {
                        hasContent = true;
                    }
                }

                for (const element of elements) {
                    (element as HTMLElement).style.height = `${maxHeight}px`;

                    if (!hasContent) {
                        (element as HTMLElement).style.display = 'none';
                    }
                }
            }
        };

        adjustHeights();
    }, []);
};
