import type { ComponentChildren, FunctionalComponent } from 'preact';

import { BlockWrapper } from '../block-wrapper/block-wrapper.js';
import { cnGallery } from './gallery.constants.js';

import './gallery.css';

type BlockAttributes = {
    children: ComponentChildren;
    className?: string;
};

export interface GalleryProps extends BlockAttributes {}

export const Gallery: FunctionalComponent<GalleryProps> = ({ children, className }) => {
    return <BlockWrapper className={cnGallery({}, className)}>{children}</BlockWrapper>;
};
