import type { RefObject } from 'preact';
import { useCallback, useEffect, useRef } from 'preact/hooks';

const OUTER_PADDING = -16;

export const useClickScroll = (slider: RefObject<HTMLDivElement>) => {
    const scrollSize = useRef(0);

    const handleScrollLeft = useCallback(() => {
        if (!slider.current) return;

        slider.current.scrollBy({ left: -(scrollSize.current - OUTER_PADDING), behavior: 'smooth' });
    }, [slider]);

    const handleScrollRight = useCallback(() => {
        if (!slider.current) return;

        slider.current.scrollBy({ left: scrollSize.current - OUTER_PADDING, behavior: 'smooth' });
    }, [slider]);

    useEffect(() => {
        if (!slider.current) return undefined;

        const resizeObserver = new ResizeObserver((entries) => {
            if (!entries[0]) return;

            const { width } = entries[0].contentRect;
            const maxWidth = 500;

            scrollSize.current = Math.min(width, maxWidth);
        });

        resizeObserver.observe(slider.current as Element);

        return () => {
            resizeObserver.disconnect();
        };
    }, [slider]);

    return { handleScrollLeft, handleScrollRight };
};
