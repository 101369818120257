import type { ComponentChildren, FunctionalComponent } from 'preact';
import { useRef } from 'preact/hooks';

import { ArrowButton } from 'src/shared/components/arrow-button/arrow-button.js';
import { BlockWrapper } from 'src/shared/components/block-wrapper/block-wrapper.js';

import { cnBlockCardsProSlider } from './block-cards-pro-slider.constants.js';
import { useClickScroll } from './block-cards-pro-slider.hooks/use-click-scroll.js';
import { useDragScroll } from './block-cards-pro-slider.hooks/use-drag-scroll.js';
import { useLeftPadding } from './block-cards-pro-slider.hooks/use-left-padding.js';

import './block-cards-pro-slider.css';

type BlockAttributes = {
    children: ComponentChildren;
};

export interface BlockCardsProSliderProps extends BlockAttributes {}

export const BlockCardsProSlider: FunctionalComponent<BlockCardsProSliderProps> = (props) => {
    const { children } = props;
    const wrapperRef = useRef<HTMLInputElement>(null);
    const itemsRef = useRef<HTMLInputElement>(null);

    useLeftPadding(wrapperRef, itemsRef);
    useDragScroll(itemsRef);
    const { handleScrollLeft, handleScrollRight } = useClickScroll(itemsRef);

    return (
        <div className={cnBlockCardsProSlider({})}>
            <div className={cnBlockCardsProSlider('items')} ref={itemsRef}>
                {children}
            </div>
            <BlockWrapper ref={wrapperRef} className={cnBlockCardsProSlider('arrows')}>
                <ArrowButton onPointerDown={handleScrollLeft} />
                <ArrowButton onPointerDown={handleScrollRight} right />
            </BlockWrapper>
        </div>
    );
};
